import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

const AboutYouPage = props => (
  <Layout>
    <SEO title="About You" />

    <BackgroundImage
      fluid={props.data.aboutYouImage.childImageSharp.fluid}
      alt=""
    >
      <Header headerText="About You"></Header>
    </BackgroundImage>
    <Section>
      <p>
        It's all about you. Well, you and your loved ones. Your family's
        financial futures are woven together although changes will occur
        throughout your lives. We will take time to really understand these
        intricacies, to ensure your plan matches your requirements, but is also
        flexible and adaptable.
      </p>
      <p>
        We also understand that everyone is different and that should be
        embraced. Your goals will be unique to you and therefore so should your
        plan be. It is essential that we provide a bespoke service for you and
        your family.
      </p>
      <p style={{ textAlign: "center" }}>
        <strong>You talk, we listen, then act accordingly.</strong>
      </p>

      <TwmButton toRef="/contact-us">Contact Us</TwmButton>
    </Section>
  </Layout>
)

export default AboutYouPage

export const pageQuery = graphql`
  query {
    aboutYouImage: file(relativePath: { eq: "about-you.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
